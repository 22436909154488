/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fullcalendar/core/main.min.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "assets/pages/tables.scss";

.layout-menu-wrapper .menu-logo .app-name img {
    height: 45px;
    padding-left: 0px;
}

.p-link {
    color: #fff;
}

.layout-wrapper {
    background: none;
}

.layout-breadcrumb-right-items {
    display: none !important;
}

.layout-rightmenu {
    width: 26rem;
}

.exception-body .exception-footer .exception-appname {
    width: 200px;
}

.p-field {
    margin-top: 1rem;
}

td {
    max-width: 0px;
}

@media all and (max-width: 3004px) {
    .phoneNumber {
        display: none;
    }
}

.logo-bg {
    background-color: #fff;
    padding: 10px;
    border-radius: 12px;
    width: 100%;
}

.p-avatar {
    text-transform: uppercase;
}

p-toolbar .p-mb-2 {
    margin-bottom: 0px !important;
}

.p-datatable.p-datatable:not(.p-datatable-gridlines) .p-datatable-tbody>tr>td {
    cursor: pointer;
}

.p-fluid .p-inline-message {
    margin-bottom: 15px;
}

.add-syllabus-entry {
    min-width: 120px;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
}

.syllabus .number {
    width: 30px;
    float: left;
}

.syllabus .title {
    width: 68%;
    float: left;
}

.syallbus .actions {
    width: 30%;
    float: left;
}

.p-tree .p-tree-container .p-treenode {
    padding: 10px;
    border-radius: 10px;
}

.dark .p-tree .p-tree-container .p-treenode {
    background-color: #393a47;
    border: solid 1px #fff;
}

.light .p-tree .p-tree-container .p-treenode {
    background-color: #f1f1f5;
    border: solid 1px #393a47;
}

.p-treenode-label {
    width: 100%;
    padding-top: 10px;
}

.p-treenode-label p-button {
    float: right;
    padding-left: 10px;
}

.p-trenode .p-inputswitch {
    float: right;
    margin-top: 5px;
}

.p-fluid .p-inputnumber,
.p-fluid p-inputnumber:not(.w-full) {
    width: 95px !important;
    float: right;
    margin-right: 5px;
}

.w-full .p-inputnumber,
.w-full.p-inputnumber {
    width: 100% !important;
}

.p-fluid .p-inputnumber,
.p-fluid p-inputnumber {
    float: right;
    margin-right: 5px;
}

p-dialog .p-fluid .p-inputnumber,
p-dialog .p-fluid p-inputnumber {
    float: left;
}

#events button {
    margin-left: 5px;
}

#fc_frame,
#fc_frame.fc-widget-normal {
    bottom: 40px !important;
    right: 20px !important;
}

.p-datepicker {
    width: 310px !important;
}

.p-datepicker th {
    width: 30px !important;
}

/*Inscritos*/
.table-filters label {
    color: #fff;
    font-size: 16px;
}

@media (max-width: 900px) {
    .tittle-inscritos {
        display: none;
    }

}

.pd {
    font-size: 14px;
}

.block {
    display: block;
}

.p-treenode-dragover .p-treenode-droppoint {
    min-height: 15px;
    margin: 2px;
    border: dashed 2px #c1c1c1;
}

.file {
    overflow: hidden;
    text-overflow: ellipsis;
}


.spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    /* High z-index so it is on top of the page */
    top: 45%;
    right: 45%;
    /* or: left: 50%; */
    //margin-top: -..px;
    /* half of the elements height */
    //margin-right: -..px;
    /* half of the elements width */
}

.p-autocomplete-clear-icon {
    right: 6px;
}

.p-multiselect-clear-icon {
    right: 26px;
}


.p-float-label.p-input-number label {
    margin-top: 0.5rem;
}

.p-float-label.p-input-number.p-input-number-array>label {
    left: 0.6rem;
}

.p-float-label.p-input-number p-inputnumber.p-inputwrapper-filled~label,
.p-float-label.p-input-number p-inputnumber.p-inputwrapper-focus~label,
.p-float-label.p-input-number.p-input-number-array p-inputnumber.p-inputwrapper-filled~label,
.p-float-label.p-input-number.p-input-number-array p-inputnumber.p-inputwrapper-focus~label {
    top: -1.6rem;
    font-size: 12px;
}

.p-float-label-custom {
    position: relative;
    display: inline;
    width: 100%;
}

.p-float-label-custom label {
    position: absolute;
    pointer-events: none;
    top: 65%;
    margin-top: -.7rem;
    transition-property: all;
    transition-timing-function: ease;
    line-height: 1;
    font-size: 12px;
    left: 1.0rem;
    color: #868c9b;
}

.input-mask-calendar {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.custom-dialog-buttons {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #252636;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 1rem 1.714rem 1.714rem 1.714rem;
}

code {
    background-color: #252636 !important;
}

label.info {
    color: #868C9B;
    font-size: 12px;
    margin-top: 0.15rem;
}

.custom-badge .p-button .p-badge {
    /* margin-left: 0.5rem; */
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #fff;
    background-color: #f1da03f7;
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 0.7rem;
    font-weight: 500;
}

.p-fileupload-choose.p-button-success:not(.p-disabled):active {
    background: #0BD18A;
    border: 1px solid transparent;
}

.p-fileupload-choose.p-button-success:not(.p-disabled):hover {
    background: #049B65;
    border: 1px solid transparent;
}

editor.ng-invalid.ng-touched {
    color: red;
    border: 2px solid;
}